<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">协议审批管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">协议审批列表</a>
                </span>
            </div>
            <!-- 条件查询区域和列表区域 -->
            <div class="framePage-body">
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <!-- 搜索区域 -->
                    <div class="searchbox" style="margin-bottom: 15px">
                        <div title="协议名称" class="searchboxItem ">
                            <span class="itemLabel">协议名称:</span>
                            <el-input v-model="searchForm.protocolName" placeholder="请输入协议名称" type="text" clearable
                                size="small" />
                        </div>
                        <div title="协议类型" class="searchboxItem">
                            <span class="itemLabel">协议类型:</span>
                            <el-select clearable placeholder="请选择协议类型" size="small" v-model="searchForm.protocolType">
                                <el-option v-for="item in protocolTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="签订机构" class="searchboxItem">
                            <span class="itemLabel">签订机构:</span>
                            <el-select size="small" v-model="searchForm.signCompId" remote :remote-method="getCompanyList"
                                filterable clearable placeholder="请至少输入两个字搜索">
                                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                                    :value="item.compId"></el-option>
                            </el-select>
                        </div>
                        <div title="经办人" class="searchboxItem">
                            <span class="itemLabel">经办人:</span>
                            <el-select clearable placeholder="请选择经办人" size="small" v-model="searchForm.createId">
                                <el-option v-for="item in operatorList" :key="item.userId" :label="item.fullname"
                                    :value="item.userId">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="searchbox">
                        <div title="行政区划" class="searchboxItem ci-full">
                            <span class="itemLabel">行政区划:</span>
                            <el-cascader clearable filterable v-model="searchForm.areaId" :options="areatreeList"
                                :props="propsarea" size="small"></el-cascader>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(true)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="addAgreement">新增</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table ref="multipleTable" :data="listData" :height="tableHeight" size="small"
                            tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
                            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
                            <el-table-column min-width="120" label="协议名称" align="left" prop="protocolName"
                                show-overflow-tooltip />
                            <el-table-column label="协议类型" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ $setDictionary("PROTOCOL_TYPE", row.protocolType) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath"
                                min-width="220px" />
                            <el-table-column min-width="180" label="签订机构" align="left" prop="compName"
                                show-overflow-tooltip />
                            <el-table-column min-width="100" label="签订日期" align="left" prop="signDate"
                                show-overflow-tooltip />
                            <el-table-column min-width="160" label="有效期" align="left" prop="startDate"
                                show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ row.startDate }} - {{ row.endDate }}
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100" label="结算标准" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    {{ $setDictionary("SETTLEMENT_STANDARD_PAYMENT", row.settleStandardType) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="对接业务员" align="left" prop="salesman" show-overflow-tooltip />
                            <el-table-column min-width="120" label="协议状态" align="left" prop="processValue"
                                show-overflow-tooltip />
                            <el-table-column label="经办人" align="left" prop="operator" show-overflow-tooltip />
                            <el-table-column min-width="150" label="创建时间" align="left" prop="createTime"
                                show-overflow-tooltip />
                            <el-table-column label="审核" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <span>{{ row.reviewer }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="审批" align="left" show-overflow-tooltip>
                                <template v-slot="{ row }">
                                    <span>{{ row.approver }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" />
                            <el-table-column label="操作" align="left" prop="department" show-overflow-tooltip width="240"
                                fixed="right">
                                <template v-slot="{ row }">
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        :disabled="row.auditNode !== '10'" @click="financialReview(row)">{{
                                            row.isFinancialReview ? '提交财务审核' : '提交领导审核' }}
                                    </el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        :disabled="row.auditNode === '100'" @click="editProtocol(row)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;"
                                        @click="deleteProtocol(row)">删除</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px"
                                        @click="viewLog(row)">查看日志</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange" />
            </div>
        </div>
    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "agreementApproval",
    components: {
        Empty,
        PageNum
    },
    mixins: [List],
    created() {
        // 处理协议类型字典数据
        const protocolType = this.$setDictionary("PROTOCOL_TYPE", "list");
        Object.keys(protocolType).forEach((key) => {
            this.protocolTypeOptions.push({
                label: protocolType[key],
                value: key
            });
        });
        this.getListData();
        // this.processList();
        this.getUserInfo();
        this.getareatree();
    },
    data() {
        return {
            // 协议列表数据
            listData: [],
            // 查询条件表单
            searchForm: {
                protocolName: "", //协议名称
                protocolType: "", //协议类型
                compName: "",// 签订机构
                createId: "",// 经办人
                areaId: "", // 行政区划
            },
            // 分页器数据
            pagination: {
                total: 0,
                size: 10,
                current: 1
            },
            // 协议类型
            protocolTypeOptions: [],
            // 签订机构数据
            companyList: [],
            // 经办人数据
            operatorList: [],
            // 审批状态
            approvalStatus: [],
            areatreeList: [], // 行政区划
            // 行政区划 - 数据格式
            propsarea: {
                value: "value",
                label: "label",
                emitPath: false,
                checkStrictly: true,
            },
        }
    },
    methods: {
        // 获取行政区划
        getareatree() {
            this.$post("/sys/area/tree").then((ret) => {
                this.areatreeList = ret.data;
            });
        },
        // 查看日志
        viewLog(row) {
            this.$router.push({
                path: "/web/financialSettlement/viewLog2",
                query: {
                    protocolId: row.protocolId
                },
            });
        },
        // 获取协议列表
        getListData(isQuery) {
            const { searchForm, pagination } = this;
            if (isQuery) pagination.current = 1;
            // 定义查询条件
            const condition = {};
            // 合并分页器数据
            condition["pageNum"] = pagination.current;
            condition["pageSize"] = pagination.size;
            // 合并查询数据
            Object.keys(searchForm).forEach((key) => {
                if (searchForm[key]) condition[key] = searchForm[key];
            });
            this.$post("/biz/protocol/pageList", condition).then(res => {
                this.listData = res.data.list;
                pagination.total = res.data.total;
            }).catch(e => {
                return e;
            });
        },
        // 获取审核流程状态
        // processList(){
        //     this.$post("/biz/audit/templateProcess/processList",{
        //         templateKey:"FD_PROTOCOL_AUDIT_TYPE"
        //     }).then(res=>{
        //         this.approvalStatus=res.data||[];
        //     });
        // },
        // 分页器当前页改变
        currentChange(val) {
            const { pagination } = this;
            pagination.current = val;
            this.getListData();
        },
        // 分页器条数改变
        sizeChange(val) {
            const { pagination } = this;
            pagination.size = val;
            this.getListData();
        },
        // 新增
        addAgreement() {
            this.$router.push({ path: "/web/agreementAdd" });
        },
        // 编辑
        editProtocol(row) {
            const { protocolId } = row;
            this.$router.push({
                path: "/web/agreementAdd",
                query: { protocolId }
            });
        },
        // 删除协议
        deleteProtocol(row) {
            const { protocolId } = row;
            this.$confirm('确认删除该协议?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    await this.$post("/biz/protocol/delete", { protocolId });
                    this.$message.success("删除成功");
                    this.getListData();
                } catch (e) {
                    return e;
                }
            });
        },
        // 获取签订机构数据
        getCompanyList(query) {
            if (query.trim().length >= 2) {
                this.$post("/sys/company/queryCompanyList", {
                    compName: query.trim()
                }).then((res) => {
                    this.companyList = res.data || [];
                }).catch(() => {
                    return;
                });
            } else {
                this.companyList = [];
            }
        },
        // 获取经办人数据
        async getUserInfo() {
            try {
                const res = await this.$post("/biz/audit/auditUser/getUserInfo", {
                    userRole: "10"
                });
                this.operatorList = res.data || [];
            } catch (e) {
                return e;
            }
        },
        // 提交财务复核
        async financialReview(row) {
            const { protocolId } = row;
            try {
                await this.$post("/biz/protocol/audit/submitFinancial", { protocolId });
                this.$message.success("提交复核成功");
                this.getListData();
            } catch (e) {
                console.log(e);
            }
        },
        // 设置表格高度
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom) tHeight -= 40 + 0.675 * 16 + 1;
            if (page) tHeight -= 32;
            this.tableHeight = tHeight;
        },
    },
    watch: {
        $route(to) {
            if (to.name === "agreementApproval") {
                this.getListData();
            }
        }
    }
}
</script>

<style lang="less">
.searchbox {
    .searchboxItem {
        .el-input {
            input {
                width: 100%;
            }
        }
    }
}

.el-message-box {
    .el-message-box__btns {
        .el-button--primary {
            background-color: #5C6BE8;
            border: none;

            &:hover {
                background-color: #3d4fee;
            }
        }
    }
}

.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
    height: 0 !important;
}
</style>